import { removeUploadAction } from "internal/shared/actions/uploads";
import UploadsGroupAction from "internal/shared/components/uploads/UploadsGroupAction";
import VideoUploads from "internal/shared/components/uploads/VideoUploads";
import IDS from "internal/shared/constants/ids";
import UPLOAD_STATUS from "internal/shared/constants/uploadStatuses";
import useVideoDropzone from "internal/shared/hooks/useVideoDropzone";
import { BriefVideosWithVariantsFieldsFragment as IBrief } from "internal/shared/types/graphql-api";
import { IUpload } from "internal/shared/types/upload";
import textualize from "internal/shared/utils/textualize";
import { Prompt } from "react-router";
import Icon from "shared/components/Icon";
import COLORS from "shared/constants/colors";
import useBeforeUnload from "shared/hooks/useBeforeUnload";
import CheckIcon from "shared/images/icons/check.svg";
import CloudSmallIcon from "shared/images/icons/cloud-small.svg";
import CloudIcon from "shared/images/icons/cloud.svg";
import ExpandIcon from "shared/images/icons/expand.svg";
import ShrinkIcon from "shared/images/icons/shrink.svg";
import WarningIcon from "shared/images/icons/warning.svg";
import { IAction } from "shared/types/actions";
import {
  AddVideoLinkButton,
  DragPrompt,
  GroupIcon,
  Groups,
  Header,
  StyledCloudIcon,
  StyledHeading,
  ToggleButton,
  UploadGroup,
  UploadGroupInfo,
  UploadsContainer,
} from "./styles";

interface IVideoUpload {
  filename: string;
  startTime: string;
  uploads: IUpload[];
}

interface IProps {
  addUploads: (files: File[], failure?: string) => void;
  brief: IBrief;
  dispatch: React.Dispatch<IAction>;
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
  uploads: IUpload[];
}

export const PER_STATUS_SETTINGS = {
  [UPLOAD_STATUS.FAILURE]: {
    color: COLORS.RED,
    groupActionCTA: "Clear all errors",
    icon: WarningIcon,
  },
  [UPLOAD_STATUS.PROCESSING]: {
    color: COLORS.YELLOW,
    groupActionCTA: "Cancel in progress uploads",
    icon: CloudSmallIcon,
  },
  [UPLOAD_STATUS.COMPLETE]: {
    color: COLORS.GREEN,
    groupActionCTA: "Clear all successful",
    icon: CheckIcon,
  },
};

const SEQUENCE_REGEX = /(.*)_(\d\d)\./;
function groupItems(uploads: IUpload[]) {
  const groupedItems: { [key: string]: IUpload[] } = {};
  uploads.forEach((upload: IUpload) => {
    let filenameStem: string;
    if (!upload.parsedFilename) {
      const regexedFilename = SEQUENCE_REGEX.exec(upload.filename);
      filenameStem = regexedFilename ? regexedFilename[1] : upload.filename;
    } else {
      const { videoShortHash, adUnit, ratio, language } = upload.parsedFilename;
      filenameStem = `${videoShortHash}_${adUnit ? `${adUnit}_` : ""}${
        ratio ? ratio.replace(":", "x") : ""
      }_${language}`;
    }

    const key = `${upload.startTime}~${filenameStem}`;
    groupedItems[key] = [...(groupedItems[key] || []), upload];
  });
  return groupedItems;
}

export function groupUploads(uploads: IUpload[]) {
  const groupedItems = groupItems(uploads);
  const group = {
    [UPLOAD_STATUS.FAILURE]: [] as IVideoUpload[],
    [UPLOAD_STATUS.PROCESSING]: [] as IVideoUpload[],
    [UPLOAD_STATUS.COMPLETE]: [] as IVideoUpload[],
  };
  Object.entries(groupedItems).forEach(
    ([key, videoUploads]: [string, IUpload[]]) => {
      const [startTime, filename] = key.split("~");
      if (videoUploads.some((upload) => !!upload.failure)) {
        // If any of the videos uploads have failed, it's a failure
        group[UPLOAD_STATUS.FAILURE].push({
          filename,
          startTime,
          uploads: videoUploads,
        });
      } else if (!videoUploads.every((upload) => upload.complete)) {
        // If any of the videos uploads haven't completed yet the video is still processing
        group[UPLOAD_STATUS.PROCESSING].push({
          filename,
          startTime,
          uploads: videoUploads,
        });
      } else {
        // Otherwise the video upload items are done
        group[UPLOAD_STATUS.COMPLETE].push({
          filename,
          startTime,
          uploads: videoUploads,
        });
      }
    },
  );
  return group;
}

function UploadsManager({
  addUploads,
  brief,
  dispatch,
  expanded,
  onExpand,
  uploads,
}: IProps) {
  const groupedVideoUploads = groupUploads(uploads);

  const { getRootProps, isDragActive, getInputProps } = useVideoDropzone({
    addUploads,
    disabled: !expanded,
  });

  const outerProps = {
    ...getRootProps(),
    onClick: undefined,
  };

  const { onClick: pickUploads } = getRootProps();

  const hasActiveUploads =
    groupedVideoUploads[UPLOAD_STATUS.PROCESSING].length > 0;

  useBeforeUnload(hasActiveUploads);

  return (
    <UploadsContainer
      {...outerProps}
      expanded={expanded}
      id={IDS.UPLOADS.MANAGER.CONTAINER}
    >
      <Prompt
        message={(location) => {
          const allowedPath = location.pathname.startsWith(
            `/curation/${brief.shortHash}/videos`,
          );
          if (hasActiveUploads && !allowedPath) {
            return textualize("upload.manager.inProgressNavigation") as string;
          }
          return true;
        }}
      />
      <Header id={IDS.UPLOADS.MANAGER.HEADER}>
        {expanded && (
          <div>
            <StyledHeading>{textualize("upload.manager.header")}</StyledHeading>
            <AddVideoLinkButton
              id={IDS.UPLOADS.MANAGER.ADD_VIDEO_BUTTON}
              onClick={pickUploads}
            >
              {textualize("upload.manager.addVideo")}
            </AddVideoLinkButton>
          </div>
        )}
        <ToggleButton
          id={IDS.UPLOADS.MANAGER.TOGGLE_EXPAND}
          onClick={() => onExpand(!expanded)}
          style={{
            pointerEvents: !expanded && isDragActive ? "none" : "auto",
          }}
        >
          <Icon
            component={expanded ? ShrinkIcon : ExpandIcon}
            label={
              expanded
                ? textualize("general.resize.close")
                : textualize("general.resize.expand")
            }
          />
        </ToggleButton>
      </Header>
      {expanded && isDragActive && (
        <DragPrompt>
          <StyledCloudIcon component={CloudIcon} />
          {textualize("upload.dropzone.activePrompt")}
        </DragPrompt>
      )}
      <Groups>
        {[
          UPLOAD_STATUS.FAILURE,
          UPLOAD_STATUS.PROCESSING,
          UPLOAD_STATUS.COMPLETE,
        ].map((status: string) => {
          const orderedUploads = groupedVideoUploads[status].sort(
            (a, b) =>
              parseInt(b.uploads[0].startTime || "0", 10) -
              parseInt(a.uploads[0].startTime || "0", 10),
          );
          const settings = PER_STATUS_SETTINGS[status];
          return (
            <UploadGroup
              id={`${IDS.UPLOADS.MANAGER.UPLOAD_GROUP}_uploadGroup-${status}`}
              key={`uploadGroup-${status}`}
            >
              <UploadGroupInfo>
                <GroupIcon
                  component={settings.icon}
                  count={orderedUploads.length}
                  status={status}
                />
                {orderedUploads.length}
              </UploadGroupInfo>
              {orderedUploads.map(({ filename, startTime, uploads: items }) => (
                <VideoUploads
                  brief={brief}
                  dispatch={dispatch}
                  expanded={expanded}
                  filename={filename}
                  items={items}
                  key={`${filename}~${startTime}`}
                  onExpand={onExpand}
                  status={status}
                />
              ))}
              {!!orderedUploads.length && (
                <UploadsGroupAction
                  confirmationRequired={status === UPLOAD_STATUS.PROCESSING}
                  cta={settings.groupActionCTA}
                  id={`${IDS.UPLOADS.GROUP_ACTION}-${status}`}
                  onAction={() => {
                    orderedUploads.forEach((item) =>
                      item.uploads.forEach((upload) =>
                        dispatch(removeUploadAction(upload.id)),
                      ),
                    );
                  }}
                />
              )}
            </UploadGroup>
          );
        })}
      </Groups>
      <input {...getInputProps()} />
    </UploadsContainer>
  );
}

export default UploadsManager;
