import { gql } from "@apollo/client";
import { BriefFields } from "internal/shared/fragments/graphql-api/brief";
import { BrandFields } from "shared/fragments/brand";

export const MegaSearchBriefFields = gql`
  fragment MegaSearchBriefFields on Brief {
    ...BriefFields
    brand {
      ...BrandFields
    }
  }
  ${BrandFields}
  ${BriefFields}
`;

export const GET_MEGA_SEARCH_DATA = gql`
  query GetMegaSearchData($searchTerm: String!) {
    brands: brandsByNameSearchTerm(searchTerm: $searchTerm) {
      edges {
        node {
          ...BrandFields
        }
      }
      pageInfo {
        hasNextPage
      }
    }
    briefs: briefsSearch(searchTerm: $searchTerm) {
      edges {
        node {
          ...MegaSearchBriefFields
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${BrandFields}
  ${MegaSearchBriefFields}
`;

export const GET_BRANDS_ONLY_MEGA_SEARCH_DATA = gql`
  query GetBrandsOnlyMegaSearchData($searchTerm: String!) {
    brands: brandsByNameSearchTerm(searchTerm: $searchTerm) {
      edges {
        node {
          ...BrandFields
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${BrandFields}
`;

export default {
  GET_BRANDS_ONLY_MEGA_SEARCH_DATA,
  GET_MEGA_SEARCH_DATA,
};
