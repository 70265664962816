import { gql } from "@apollo/client";
import { VariantFields } from "shared/fragments/variant";

export const GET_VARIANT = gql`
  query getVariant($id: ID!) {
    variant(id: $id) {
      ...VariantFields
    }
  }
  ${VariantFields}
`;

export default GET_VARIANT;
