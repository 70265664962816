import UploadItemProcessing from "internal/shared/components/UploadItem/controllers/UploadItemProcessing";
import UploadItemProgress from "internal/shared/components/UploadItem/controllers/UploadItemProgress";
import UploadItemFailure from "internal/shared/components/UploadItem/statuses/UploadItemFailure";
import UPLOAD_STATUS from "internal/shared/constants/uploadStatuses";
import { VariantFieldsFragment as IVariant } from "internal/shared/types/graphql-api";
import { IUpload } from "internal/shared/types/upload";

export interface IProps {
  briefID: string;
  onFailure: (reason: string) => void;
  onProcessed: (variant: IVariant) => void;
  onProgress: (amount: number) => void;
  onUpload: (key: string) => void;
  sequenceNumber: number;
  sequenceVariantProcessed: boolean;
  upload: IUpload;
  videoID: string;
}

export const getStatusComponent = (status: string, props: IProps) => {
  const {
    briefID,
    onFailure,
    onProcessed,
    onProgress,
    onUpload,
    sequenceNumber,
    sequenceVariantProcessed,
    upload,
    videoID,
  } = props;

  switch (status) {
    case UPLOAD_STATUS.FAILURE:
      return <UploadItemFailure reason={upload.failure} />;
    case UPLOAD_STATUS.PROGRESS:
      return (
        <UploadItemProgress
          onFailure={onFailure}
          onProgress={onProgress}
          onUpload={onUpload}
          upload={upload}
        />
      );
    case UPLOAD_STATUS.PROCESSING:
      return (
        <UploadItemProcessing
          briefID={briefID}
          onComplete={onProcessed}
          onFailure={onFailure}
          sequenceNumber={sequenceNumber}
          sequenceVariantProcessed={sequenceVariantProcessed}
          upload={upload}
          videoID={videoID}
        />
      );
    case UPLOAD_STATUS.UPLOADED:
    default:
      return null;
  }
};

export default getStatusComponent;
