import { gql } from "@apollo/client";

const CREATE_VIDEO = gql`
  mutation createVideoForVariants($brief: VideoInput!) {
    video: createVideoForVariants(input: $brief) {
      id
      shortHash
    }
  }
`;

export default CREATE_VIDEO;
