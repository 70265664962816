import { gql } from "@apollo/client";
import { BriefFields } from "internal/shared/fragments/graphql-api/brief";
import { ContentGroupFields } from "internal/shared/fragments/graphql-api/contentGroup";
import { Rating } from "internal/shared/fragments/graphql-api/video";
import { ViewOptions } from "internal/shared/fragments/graphql-api/viewOptions";
import { BrandFields } from "shared/fragments/brand";
import { VideoFieldsWithoutNotes } from "shared/fragments/video";

export const TaskViewVideoFields = gql`
  fragment TaskViewVideoFields on Video {
    ...Rating
    ...VideoFieldsWithoutNotes
  }
  ${Rating}
  ${VideoFieldsWithoutNotes}
`;

export const GET_TASK_VIEW_OLD = gql`
  query GetTaskViewOld($shortHash: ID!, $includeHidden: Boolean!) {
    brief(shortHash: $shortHash) {
      ...BriefFields
      brand {
        ...BrandFields
      }
      complete
      contentGroups {
        ...ContentGroupFields
      }
      numberOfConcepts
      submissionDate
      videos {
        ...TaskViewVideoFields
      }
      viewOptions @client {
        ...ViewOptions
      }
    }
  }
  ${BrandFields}
  ${BriefFields}
  ${ContentGroupFields}
  ${TaskViewVideoFields}
  ${ViewOptions}
`;

export default {
  GET_TASK_VIEW_OLD,
};
