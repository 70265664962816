import { gql } from "@apollo/client";
import { VideoLabelsRatingsVariantsFields } from "internal/curation/queries/graphql-api/GetVideoLabelsWithRatings/GetVideoLabelsWithRatings";
import { BriefAspectRatioAdUnitFields } from "internal/shared/fragments/graphql-api/briefAspectRatioAdUnit";
import { ContentGroupFields } from "internal/shared/fragments/graphql-api/contentGroup";
import { Rating } from "internal/shared/fragments/graphql-api/video";
import { BrandFields } from "shared/fragments/brand";
import { VideoFieldsWithoutVariants } from "shared/fragments/video";

export const ProductionType = gql`
  fragment ProductionType on Brief {
    productionType
  }
`;

export const BriefContactFields = gql`
  fragment BriefContactFields on Brief {
    contacts {
      displayName
      email
      id
      jobTitle
    }
  }
`;

export const StaticRenderPath = gql`
  fragment StaticRenderPath on Brief {
    staticRenderPath {
      uri
      version
    }
  }
`;

export const BriefFields = gql`
  fragment BriefFields on Brief {
    id
    jobReference
    shortHash
    title
  }
`;

export const BriefFieldsWithVideosFields = gql`
  fragment BriefFieldsWithVideosFields on Video {
    contentGroup {
      id
      name
    }
    internal
    latestVersionVisible
    notes {
      approval
    }
    ownershipChanged
    ...Rating
    ...VideoFieldsWithoutVariants
  }
  ${Rating}
  ${VideoFieldsWithoutVariants}
`;

export const BriefFieldsWithVideosVariants = gql`
  fragment BriefFieldsWithVideosVariants on Brief {
    ...BriefFields
    brand {
      ...BrandFields
    }
    contentGroups {
      ...ContentGroupFields
    }
    videos {
      ...BriefFieldsWithVideosFields
      ...VideoLabelsRatingsVariantsFields
    }
  }
  ${BrandFields}
  ${BriefFields}
  ${BriefFieldsWithVideosFields}
  ${ContentGroupFields}
  ${VideoLabelsRatingsVariantsFields}
`;

export const BriefVideosWithVariantsFields = gql`
  fragment BriefVideosWithVariantsFields on Brief {
    ...BriefFieldsWithVideosVariants
    aspectRatioAdUnits {
      ...BriefAspectRatioAdUnitFields
    }
    languages
  }
  ${BriefAspectRatioAdUnitFields}
  ${BriefFieldsWithVideosVariants}
`;

export default {
  BriefFields,
  BriefFieldsWithVideosVariants,
  ProductionType,
};
