import { gql } from "@apollo/client";
import { BriefVideosWithVariantsFields } from "internal/shared/fragments/graphql-api/brief";

export const GET_BRIEF_VIDEOS_WITH_VARIANTS = gql`
  query GetBriefVideosWithVariants($shortHash: ID!) {
    brief(shortHash: $shortHash) {
      ...BriefVideosWithVariantsFields
    }
  }
  ${BriefVideosWithVariantsFields}
`;

export default GET_BRIEF_VIDEOS_WITH_VARIANTS;
