import { gql } from "@apollo/client";
import { ContentGroupFields } from "internal/shared/fragments/graphql-api/contentGroup";
import { ViewOptions } from "internal/shared/fragments/graphql-api/viewOptions";
import { VariantFields } from "shared/fragments/variant";
import { VideoVariantVersionsFields } from "shared/fragments/video";

export const GET_SELECT_VIDEOS_BRIEF = gql`
  query GetSelectVideosBrief($shortHash: ID!) {
    brief(shortHash: $shortHash) {
      id
      contentGroups {
        ...ContentGroupFields
      }
      selectedVideoIds @client
      videos {
        id
        action
        concept
        contentGroup {
          ...ContentGroupFields
        }
        creatorID
        creatorName
        internal
        gifURI
        latestVersionVisible
        notes {
          approval
        }
        originalFilename
        ownershipChanged
        rating
        shortHash
        status
        thumbnailURI
        variants {
          id
          adUnit
          aspectRatio
          hidden
          language
          sequenceOrder
          type
        }
      }
      viewOptions @client {
        ...ViewOptions
      }
    }
  }
  ${ContentGroupFields}
  ${VariantFields}
  ${VideoVariantVersionsFields}
  ${ViewOptions}
`;

export const GET_SELECT_VIDEOS_PROJECT = gql`
  query GetSelectVideosProject($id: ID!) {
    project(id: $id) {
      id
      briefID
      creativeConcepts {
        id
        default
        name
        requiredVideos
        videos {
          id
          action
          concept
          creatorID
          creatorName
          gifURI
          internal
          latestVersionVisible
          notes {
            approval
          }
          order
          originalFilename
          ownershipChanged
          rating
          shortHash
          status
          thumbnailURI
          videoVariants {
            id
            clientVisible
            hidden
            language
            versions {
              id
              assets {
                id
                type
              }
            }
          }
          visible
        }
      }
      office
      requiredVideos
      selectedVideoIds @client
      tasks {
        id
        dueDate
        kind
        state
        title
      }
      viewOptions @client {
        ...ViewOptions
      }
    }
  }
  ${ViewOptions}
`;

export default {
  GET_SELECT_VIDEOS_BRIEF,
  GET_SELECT_VIDEOS_PROJECT,
};
