import { useQuery } from "@apollo/client";
import { setIsExistingVariant } from "internal/shared/actions/uploads";
import { GET_VIDEO_VARIANTS } from "internal/shared/queries/graphql-api/GetVideoVariants";
import {
  BriefAspectRatioAdUnitFieldsFragment as IBriefAspectRatioAdUnit,
  BriefFieldsWithVideosFieldsFragment as IBriefVideo,
  BriefVideosWithVariantsFieldsFragment as IBrief,
  GetVideoVariantsQuery as IGetVideoVariants,
  VariantFieldsFragment as IVideoVariants,
} from "internal/shared/types/graphql-api";
import { IUpload } from "internal/shared/types/upload";
import textualize from "internal/shared/utils/textualize";
import { useEffect } from "react";
import AD_UNITS from "shared/constants/adUnits";
import useTaxonomy from "shared/hooks/useTaxonomy";
import { IAction } from "shared/types/actions";
import Taxonomy from "shared/types/taxonomy";
import { ReplaceTag, UploadInfoWrapper } from "./styles";

export const matchingAdUnits = (
  aspectRatioAdUnits: Array<IBriefAspectRatioAdUnit | null | undefined>,
  ratio: string,
) => {
  const matches = aspectRatioAdUnits
    .filter((item) => item!.aspectRatio === ratio)
    .map((item) => item!.adUnit);

  if (matches.length > 1) {
    return `${matches.length} ${textualize("upload.video.multipleAdUnits")}`;
  } else if (matches.length === 1) {
    return `${matches[0]}`;
  }
  return "";
};

interface IUploadInfoProps {
  brief: IBrief;
  dispatch: React.Dispatch<IAction>;
  existingVideo?: IBriefVideo;
  id?: string;
  uploads: IUpload[];
}

// eslint-disable-next-line prefer-spread
const flattenedAdUnits = [].concat.apply(
  [],
  Object.values(AD_UNITS).map((brand) =>
    Object.entries(brand).filter(([key]) => key !== "BRAND"),
  ),
);

interface IAdUnitType {
  IS_SEQUENCE: boolean;
  MAP_VALUE: string;
  TYPE: string;
}

const SEQUENCED_AD_UNITS = flattenedAdUnits
  .filter(([, adUnit]: [any, IAdUnitType]) => adUnit.IS_SEQUENCE)
  .map(([, adUnit]: [any, IAdUnitType]) => adUnit.MAP_VALUE);

export const variantMatchesUpload = (
  variant: IVideoVariants,
  upload: IUpload,
) => {
  const { adUnit, language, ratio } = upload.parsedFilename!;
  const ratioCheck = variant.aspectRatio === ratio;
  const adUnitCheck = adUnit ? variant.adUnit === adUnit : true;
  const languageCheck = variant.language === language;
  return ratioCheck && adUnitCheck && languageCheck;
};

export function UploadInfo({
  brief,
  dispatch,
  existingVideo,
  id,
  uploads,
}: IUploadInfoProps) {
  const firstUpload = uploads[0];

  const languages = useTaxonomy(Taxonomy.Language);

  // Figure out if this is a replacement variant or not
  const { data, loading } = useQuery<IGetVideoVariants>(GET_VIDEO_VARIANTS, {
    skip: !existingVideo,
    variables: {
      id: existingVideo ? existingVideo.id : 0,
    },
  });

  useEffect(() => {
    if (!firstUpload || firstUpload.isExistingVariant !== undefined) {
      return;
    }
    let isExisting = false;
    const variants = data?.video?.variants;
    if (variants && variants.length !== 0) {
      isExisting = variants.some(
        (variant) => !!variant && variantMatchesUpload(variant, firstUpload),
      );
      dispatch(setIsExistingVariant(firstUpload.id, isExisting));
    } else if (!loading) {
      // If we have an existing video we can say definitively
      if (existingVideo) {
        dispatch(setIsExistingVariant(firstUpload.id, isExisting));
      }
    }
  }, [data, dispatch, existingVideo, firstUpload, loading]);

  if (!firstUpload.parsedFilename) {
    return null;
  }

  const { adUnit, ratio = "", language: langID } = firstUpload.parsedFilename;
  const displayRatio = ratio;
  const videoLanguage = languages.find((language) => language.id === langID);

  // If we're uploading a sequence then check for relevant matching sequence ad units
  // otherwise the other ad units are the only ones relevant
  const aspectRatioAdUnits = brief?.aspectRatioAdUnits || [];

  const relevantAdUnits =
    uploads.length > 1
      ? aspectRatioAdUnits.filter((item: IBriefAspectRatioAdUnit) =>
          SEQUENCED_AD_UNITS.includes(item.adUnit),
        )
      : aspectRatioAdUnits.filter(
          (item: IBriefAspectRatioAdUnit) =>
            !SEQUENCED_AD_UNITS.includes(item.adUnit),
        );

  const adUnits = adUnit || matchingAdUnits(relevantAdUnits, displayRatio);

  return (
    <UploadInfoWrapper id={id}>
      <ReplaceTag replace={firstUpload.isExistingVariant}>
        {firstUpload.isExistingVariant
          ? textualize("upload.video.replace")
          : textualize("upload.video.new")}
      </ReplaceTag>
      {displayRatio && <span>{displayRatio}</span>}
      {adUnits && <span>{adUnits}</span>}
      <span>{videoLanguage?.label}</span>
    </UploadInfoWrapper>
  );
}

export default UploadInfo;
