import { gql } from "@apollo/client";
import { VideoLabelFields } from "internal/curation/queries/graphql-api/GetVideoLabels/GetVideoLabels";
import { Rating } from "internal/shared/fragments/graphql-api/video";

const VideoLabelsRatingsVariantFields = gql`
  fragment VideoLabelsRatingsVariantFields on Variant {
    id
    adUnit
    aspectRatio
    hidden
    language
    sequenceOrder
    type
  }
`;

export const VideoLabelsRatingsVariantsFields = gql`
  fragment VideoLabelsRatingsVariantsFields on Video {
    variants {
      ...VideoLabelsRatingsVariantFields
    }
  }
  ${VideoLabelsRatingsVariantFields}
`;

const GET_VIDEO_LABELS_WITH_RATINGS = gql`
  query GetVideoLabelsWithRatings($id: ID!) {
    video(id: $id) {
      ...Rating
      ...VideoLabelFields
      ...VideoLabelsRatingsVariantsFields
    }
  }
  ${Rating}
  ${VideoLabelFields}
  ${VideoLabelsRatingsVariantsFields}
`;

export default GET_VIDEO_LABELS_WITH_RATINGS;
