import { gql } from "@apollo/client";
import { VariantFields } from "shared/fragments/variant";

export const UDPATE_VIDEO_VARIANTS = gql`
  mutation updateVideoVariantsAdUnitAndLanguage(
    $input: VideoVariantsAdUnitAndLanguageInput!
  ) {
    video: updateVideoVariantsAdUnitAndLanguage(input: $input) {
      id
      variants {
        ...VariantFields
      }
    }
  }
  ${VariantFields}
`;

export default UDPATE_VIDEO_VARIANTS;
