import { gql } from "@apollo/client";
import { VariantFields } from "shared/fragments/variant";

export const CREATE_VARIANT_FROM_SUCCESSFUL_UPLOAD = gql`
  mutation createVariantFromSuccessfulUpload(
    $input: VariantFromSuccessfulUploadInput!
  ) {
    result: createVariantFromSuccessfulUpload(input: $input) {
      variant {
        ...VariantFields
      }
      videoID
    }
  }
  ${VariantFields}
`;

export default CREATE_VARIANT_FROM_SUCCESSFUL_UPLOAD;
