/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: Refactor to adhere to react-hooks/exhaustive-deps logic */
import { useMutation, useQuery } from "@apollo/client";
import { default as ItemProcessingStatus } from "internal/shared/components/UploadItem/statuses/UploadItemProcessing";
import CREATE_VARIANT_FROM_SUCCESSFUL_UPLOAD from "internal/shared/mutations/graphql-api/createVariantFromSuccessfulUpload";
import GET_VARIANT from "internal/shared/queries/graphql-api/GetVariant/GetVariant";
import {
  CreateVariantFromSuccessfulUploadMutation as ICreateVariantFromSuccessfulUpload,
  VariantFieldsFragment as IVariant,
  GetVariantQuery as IVariantQuery,
} from "internal/shared/types/graphql-api";
import { IUpload } from "internal/shared/types/upload";
import textualize from "internal/shared/utils/textualize";
import { useEffect, useState } from "react";
import getGraphQLErrors from "shared/utils/getGraphQLErrors";

export interface IProps {
  briefID: string;
  onComplete: (variant: IVariant) => void;
  onFailure: (reason: string) => void;
  sequenceNumber?: number;
  sequenceVariantProcessed: boolean;
  upload: IUpload;
  videoID?: string;
}
export const PROCESS_POLL_INTERVAL = 2000;

export const getTranscodeError = (errorCode: string, variantID = "?") => {
  let reason: string;
  switch (errorCode) {
    default:
      reason = `${textualize("upload.dropzone.errors.type")}, ${textualize(
        "upload.dropzone.errors.codec",
      )}`;
  }
  return `${textualize("upload.dropzone.errors.transcode", {
    reason,
    variantID,
  })}`;
};

const UploadItemProcessing = ({
  briefID,
  onComplete,
  onFailure,
  sequenceNumber,
  sequenceVariantProcessed,
  upload,
  videoID,
}: IProps) => {
  const [variant, setVariant] = useState<IVariant>();

  const { data, error } = useQuery<IVariantQuery>(GET_VARIANT, {
    pollInterval: PROCESS_POLL_INTERVAL,
    skip: !variant?.id,
    variables: {
      id: variant ? variant.id : "",
    },
  });

  const isComplete = (data?.variant?.transcoded ?? false) && videoID;

  const [createVariantFromSuccessfulUploadMutation] =
    useMutation<ICreateVariantFromSuccessfulUpload>(
      CREATE_VARIANT_FROM_SUCCESSFUL_UPLOAD,
    );

  useEffect(() => {
    if (
      !videoID ||
      // Do not process the other items of a sequence until the first item has successfully created a variant
      (sequenceNumber && sequenceNumber > 1 && !sequenceVariantProcessed)
    ) {
      return;
    }

    const confirmUpload = async () => {
      try {
        const response = await createVariantFromSuccessfulUploadMutation({
          variables: {
            input: {
              briefID,
              key: upload.uploadKey,
              originalFileName: upload.filename,
              videoID,
            },
          },
        });
        const variantResponse = response.data?.result.variant;
        setVariant(variantResponse);
      } catch {
        onFailure(textualize("upload.dropzone.errors.createVariant") as string);
      }
    };
    confirmUpload();
  }, [sequenceNumber, sequenceVariantProcessed, videoID]);

  useEffect(() => {
    if (isComplete) {
      const latestVariant = data?.variant as IVariant;
      const expectedRatio = upload.parsedFilename?.ratio || "";
      if (latestVariant.aspectRatio !== expectedRatio) {
        // Aspect ratio after transcoding doesn't match what was specified in the filename
        const reason = `${textualize(
          "upload.dropzone.errors.aspectRatioMismatch",
        )}`;
        onFailure(
          `${textualize("upload.dropzone.errors.transcode", {
            reason,
            variantID: latestVariant.id,
          })}`,
        );
      } else {
        setVariant(latestVariant);
        onComplete(latestVariant);
      }
    }
  }, [isComplete]);

  useEffect(() => {
    if (!error) {
      return;
    }
    const errorCode = getGraphQLErrors(error)?.[0];
    const variantID = data?.variant?.id;
    onFailure(getTranscodeError(errorCode, variantID));
  }, [error]);

  return <ItemProcessingStatus />;
};

export default UploadItemProcessing;
