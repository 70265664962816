/* eslint-disable react-hooks/exhaustive-deps */
/* TODO: Refactor to adhere to react-hooks/exhaustive-deps logic */
import { useMutation } from "@apollo/client";
import { DocumentNode, getOperationAST } from "graphql";
import ANALYTICS from "internal/curation/constants/analytics";
import { GET_SELECT_VIDEOS_PROJECT } from "internal/curation/queries/graphql-api/GetSelectVideos";
import { GET_TASK_VIEW } from "internal/curation/queries/graphql-api/GetTaskView";
import { GET_TASK_VIEW_OLD } from "internal/curation/queries/graphql-api/GetTaskViewOld";
import {
  completeAction,
  failureAction,
  processedAction,
  removeUploadAction,
  setPlaceholderVideo,
  setUploadCompleteAction,
  setUploadProgressAction,
} from "internal/shared/actions/uploads";
import UploadItem from "internal/shared/components/UploadItem";
import IDS from "internal/shared/constants/ids";
import UPLOAD_STATUS from "internal/shared/constants/uploadStatuses";
import CREATE_VIDEO from "internal/shared/mutations/graphql-api/createVideo";
import UDPATE_VIDEO_VARIANTS from "internal/shared/mutations/graphql-api/updateVideoVariants";
import GET_BRIEF_VIDEOS_WITH_VARIANTS from "internal/shared/queries/graphql-api/GetBriefVideosWithVariants";
import { GET_VIDEO_VARIANTS } from "internal/shared/queries/graphql-api/GetVideoVariants";
import {
  BriefVideosWithVariantsFieldsFragment as IBrief,
  BriefFieldsWithVideosFieldsFragment as IBriefVideo,
  UpdateVideoVariantsAdUnitAndLanguageMutation as IUpdateVideoVariantsAdUnitAndLanguage,
} from "internal/shared/types/graphql-api";
import { IUpload } from "internal/shared/types/upload";
import getStatusComponent from "internal/shared/utils/getStatusComponent";
import textualize from "internal/shared/utils/textualize";
import { useCallback, useContext, useEffect, useState } from "react";
import CopyableContent from "shared/components/CopyableContent";
import Icon from "shared/components/Icon";
import { ToastContext } from "shared/components/ToastProvider";
import useBriefInBetaWorkflowRelease from "shared/hooks/useBriefInBetaWorkflowRelease";
import CancelIcon from "shared/images/icons/cancel.svg";
import CleanIcon from "shared/images/icons/clean.svg";
import PlayIcon from "shared/images/icons/video/play-filled.svg";
import { IAction } from "shared/types/actions";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import UploadInfo from "./UploadInfo";
import {
  CancelButton,
  ConfirmButton,
  Controls,
  DeleteButton,
  PlayLink,
  STATUS_STYLES,
  Status,
  UploadName,
  VideoUpload,
  VideoUploadsContainer,
} from "./styles";

interface IProps {
  brief: IBrief;
  dispatch: React.Dispatch<IAction>;
  expanded: boolean;
  filename: string;
  items: IUpload[];
  onExpand: (expanded: boolean) => void;
  status: string;
}

export function removeItems(
  dispatch: React.Dispatch<IAction>,
  items: IUpload[],
) {
  // Analytics?
  items.forEach((upload) => dispatch(removeUploadAction(upload.id)));
}

function VideoUploads({
  brief,
  dispatch,
  expanded,
  filename,
  items,
  onExpand,
  status,
}: IProps) {
  const clientsWorkflowFlag = useBriefInBetaWorkflowRelease(brief.id);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [existingVideo, setExistingVideo] = useState<IBriefVideo | undefined>(
    undefined,
  );
  // Represents whether a variant has been created for the first item of a sequence
  const [sequenceVariantProcessed, setSequenceVariantProcessed] = useState(
    items.length < 2,
  );

  const [createVideoMutation] = useMutation(CREATE_VIDEO);

  const firstUpload = items[0];
  const adUnit = firstUpload?.parsedFilename?.adUnit;
  const aspectRatio = firstUpload?.parsedFilename?.ratio;
  const language = firstUpload?.parsedFilename?.language;
  const videoID = firstUpload?.placeholderVideo?.id || existingVideo?.id;
  const videoShortHash =
    firstUpload?.placeholderVideo?.shortHash || existingVideo?.shortHash;

  const variantURL = `/curation/${
    brief.shortHash
  }/videos/${videoShortHash}?aspect-ratio=${
    aspectRatio ? aspectRatio.replace(":", "x") : ""
  }${adUnit ? `&ad-unit=${adUnit}` : ""}${language ? `&lang=${language}` : ""}`;

  // setting refetchQueries to be the string names of the relevant queries instead of a
  // function that returns query objects ensures that the queries are refetched with
  // the last variables they were called with:
  // https://www.apollographql.com/docs/react/v2.5/advanced/caching/#updating-after-a-mutation
  const refetchQueries: (
    | { query: DocumentNode; variables: { id: string } }
    | string
  )[] = [
    // This query is defined as query object as we need access to the newly acquired id value
    {
      query: GET_VIDEO_VARIANTS,
      variables: {
        id: videoID,
      },
    },
  ];

  // Refetch videos data depending on which queries we use for new projects or old briefs
  if (clientsWorkflowFlag) {
    refetchQueries.push(
      getOperationAST(GET_BRIEF_VIDEOS_WITH_VARIANTS)?.name?.value || "", // This is still needed due to GET_BRIEF_VIDEOS_WITH_VARIANTS being used in the UploadsProvider. Remove when https://vidsy.atlassian.net/browse/PLAT-1152 is completed
      getOperationAST(GET_SELECT_VIDEOS_PROJECT)?.name?.value || "",
      getOperationAST(GET_TASK_VIEW)?.name?.value || "",
    );
  } else {
    refetchQueries.push(
      getOperationAST(GET_BRIEF_VIDEOS_WITH_VARIANTS)?.name?.value || "",
      getOperationAST(GET_TASK_VIEW_OLD)?.name?.value || "",
    );
  }

  if (videoID) {
    refetchQueries.shift();
  }

  const [updateVideoVariantsMutation] =
    useMutation<IUpdateVideoVariantsAdUnitAndLanguage>(UDPATE_VIDEO_VARIANTS, {
      // TODO: This is the brute force approach for making sure we update the relevant pages
      // Alternative is to request all actual data from the BE and write the cache manually
      // but as that isn't available from the BE on create this is the next best approach
      refetchQueries,
      variables: {
        input: {
          adUnit: adUnit ? adUnit : undefined,
          language,
          sequence: items.length > 1,
          variantIDs: items.map((upload) => upload.id),
        },
      },
    });

  useEffect(() => {
    if (!firstUpload.parsedFilename) {
      return;
    }

    const preExistingVideo = brief.videos!.find(
      (video) => video.shortHash === firstUpload.parsedFilename!.videoShortHash,
    );

    setExistingVideo(preExistingVideo);

    if (
      items.length > 1 &&
      !sequenceVariantProcessed &&
      preExistingVideo?.variants &&
      preExistingVideo.variants.length > 1
    ) {
      // Do not wait for first variant to be created if it already exists
      const preExistingVariant = preExistingVideo.variants.some(
        (variant) =>
          variant?.adUnit === adUnit &&
          variant?.aspectRatio === aspectRatio &&
          variant?.language === language,
      );
      if (preExistingVariant) {
        setSequenceVariantProcessed(true);
      }
    }

    async function createVideo() {
      const response = await createVideoMutation({
        variables: {
          brief: {
            briefID: brief.id,
          },
        },
      });
      dispatch(setPlaceholderVideo(firstUpload.id, response?.data?.video));
    }

    if (!preExistingVideo && status === UPLOAD_STATUS.PROCESSING) {
      createVideo();
    }
  }, []);

  const allProcessed = items.every((upload) => upload.processed);
  useEffect(() => {
    const submitUploads = async () => {
      try {
        await updateVideoVariantsMutation();
        items.forEach((upload) => dispatch(completeAction(upload.id)));
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.UPLOADER,
          ANALYTICS.EVENTS.ADD_VIDEO_SUCCESS,
        );
      } catch {
        items.forEach((upload) =>
          dispatch(
            failureAction(
              upload.id,
              textualize("upload.video.saveFailed") as string,
            ),
          ),
        );
        fireAnalyticsEvent(
          ANALYTICS.CATEGORIES.UPLOADER,
          ANALYTICS.EVENTS.ADD_VIDEO_ERROR,
        );
      }
    };

    // If all items have been processed and we think we're processing them
    // then submit the uploads.
    if (allProcessed && status === UPLOAD_STATUS.PROCESSING) {
      submitUploads();
    }
  }, [allProcessed, status]);

  const { addToast } = useContext(ToastContext);
  const shortHashCopied = useCallback(
    () => addToast(textualize("upload.video.shortHashCopied") as string),
    [addToast],
  );

  return (
    <VideoUploadsContainer
      expanded={expanded}
      id={`${IDS.UPLOADS.VIDEO.CONTAINER}-${firstUpload.id}`}
    >
      <Status
        id={`${IDS.UPLOADS.VIDEO.STATUS}-${firstUpload.id}`}
        status={status}
      >
        <Icon component={STATUS_STYLES[status].icon} />
      </Status>
      <VideoUpload id={`${IDS.UPLOADS.VIDEO.UPLOAD}-${firstUpload.id}`}>
        <UploadName id={`${IDS.UPLOADS.VIDEO.NAME}-${firstUpload.id}`}>
          {filename}
          {items.length > 1 ? textualize("upload.video.sequenceSuffix") : ""}
          {!!firstUpload.placeholderVideo && (
            <>
              {" "}
              (
              <CopyableContent
                onCopy={shortHashCopied}
              >{`${firstUpload.placeholderVideo.shortHash}`}</CopyableContent>
              )
            </>
          )}
        </UploadName>
        <UploadInfo
          brief={brief}
          dispatch={dispatch}
          existingVideo={existingVideo}
          id={`${IDS.UPLOADS.VIDEO.INFO}-${videoID}`}
          uploads={items}
        />
        {items.map((upload: IUpload, index: number) => (
          <UploadItem
            getStatusComponent={(status) =>
              getStatusComponent(status, {
                briefID: brief.id,
                onFailure: (reason) => {
                  fireAnalyticsEvent(
                    ANALYTICS.CATEGORIES.UPLOADER,
                    ANALYTICS.EVENTS.UPLOAD_VIDEO_ERROR,
                    {
                      reason,
                    },
                  );
                  dispatch(failureAction(upload.id, reason));
                },
                onProcessed: (variant) => {
                  fireAnalyticsEvent(
                    ANALYTICS.CATEGORIES.UPLOADER,
                    ANALYTICS.EVENTS.UPLOAD_VIDEO_SUCCESS,
                  );
                  dispatch(processedAction(upload.id, variant));
                  setSequenceVariantProcessed(true);
                },
                onProgress: (amount) =>
                  dispatch(setUploadProgressAction(upload.id, amount)),
                onUpload: (key) => {
                  dispatch(setUploadCompleteAction(upload.id, key));
                },
                sequenceNumber: items.length > 1 ? index + 1 : 0,
                sequenceVariantProcessed,
                upload,
                videoID,
              })
            }
            id={`${IDS.UPLOADS.ITEM.CONTAINER}-${upload.id}`}
            key={upload.id}
            sequenceNumber={items.length > 1 ? index + 1 : 0}
            upload={upload}
          />
        ))}
      </VideoUpload>
      <Controls>
        {status === UPLOAD_STATUS.COMPLETE && (
          <PlayLink
            id={`${IDS.UPLOADS.ITEM.PLAY}-${firstUpload.id}`}
            onClick={() => onExpand(false)}
            to={variantURL}
          >
            <Icon component={PlayIcon} />
          </PlayLink>
        )}
        {confirmDelete && (
          <>
            <CancelButton
              id={`${IDS.UPLOADS.ITEM.CANCEL_CONFIRM}-${firstUpload.id}`}
              onClick={() => setConfirmDelete(false)}
            >
              {textualize("upload.video.noCancelConfirm")}
            </CancelButton>
            <ConfirmButton
              id={`${IDS.UPLOADS.ITEM.CONFIRM}-${firstUpload.id}`}
              onClick={() => removeItems(dispatch, items)}
            >
              {textualize("upload.video.cancelConfirm")}
            </ConfirmButton>
          </>
        )}
        {!confirmDelete && (
          <DeleteButton
            id={`${IDS.UPLOADS.ITEM.DELETE}-${firstUpload.id}`}
            onClick={() => {
              switch (status) {
                case UPLOAD_STATUS.COMPLETE:
                case UPLOAD_STATUS.FAILURE:
                  removeItems(dispatch, items);
                  break;
                case UPLOAD_STATUS.PROCESSING:
                default:
                  setConfirmDelete(true);
                  break;
              }
            }}
          >
            <Icon
              component={
                UPLOAD_STATUS.PROCESSING === status ? CancelIcon : CleanIcon
              }
            />
          </DeleteButton>
        )}
      </Controls>
    </VideoUploadsContainer>
  );
}

export default VideoUploads;
