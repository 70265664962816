import { useQuery } from "@apollo/client";
import { addUploadAction } from "internal/shared/actions/uploads";
import GlobalVideoDropzone from "internal/shared/components/GlobalVideoDropzone";
import UploadsManager from "internal/shared/components/uploads/UploadsManager";
import ANALYTICS from "internal/shared/constants/analytics";
import IDS from "internal/shared/constants/ids";
import GET_BRIEF_VIDEOS_WITH_VARIANTS from "internal/shared/queries/graphql-api/GetBriefVideosWithVariants";
import uploadsReducer from "internal/shared/reducers/uploadsReducer";
import {
  BriefVideosWithVariantsFieldsFragment as IBrief,
  GetBriefVideosWithVariantsQuery as IGetBriefVideosWithVariants,
} from "internal/shared/types/graphql-api";
import { IUpload, IUploadParsedFilename } from "internal/shared/types/upload";
import Session from "internal/web/services/Session";
import { ReactNode, useCallback, useEffect, useReducer, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DataError from "shared/components/errors/DataError";
import { IAction } from "shared/types/actions";
import fireAnalyticsEvent from "shared/utils/fireAnalyticsEvent";
import styled from "styled-components";
import sequenceValidation, { RejectedFileTuple } from "./sequenceValidation";
import uploadsPreflight from "./uploadsPreflight";

interface IUploadsExpandedProps {
  expanded?: boolean;
}

export const UploadsWrapper = styled.div<IUploadsExpandedProps>`
  align-items: center;
  background: ${(props) =>
    props.expanded ? "rgba(0, 0, 0, 0.75)" : "transparent"};
  bottom: ${(props) => (props.expanded ? "0" : "1rem")};
  display: ${(props) => (props.expanded ? "flex" : "block")};
  justify-content: center;
  max-width: 100%;
  padding: ${(props) => (props.expanded ? "2rem 0" : "0")};
  position: fixed;
  right: ${(props) => (props.expanded ? "auto" : "1rem")};
  top: ${(props) => (props.expanded ? "0" : "auto")};
  width: ${(props) => (props.expanded ? "100%" : "auto")};
  z-index: 90;
`;

interface IDetailParams {
  briefShortHash?: string;
}

export interface IProps {
  children?: ReactNode;
}

function addUpload(
  file: File,
  startTime: string,
  dispatch: React.Dispatch<IAction>,
  parsedFilename?: IUploadParsedFilename,
  failure?: string,
) {
  const upload = {
    failure, // Can create an upload in an immediate fail state
    file,
    filename: file.name,
    id: `${file.name}-${startTime}`, // Temp ID while we upload/process
    parsedFilename,
    startTime,
  } as IUpload;

  dispatch(addUploadAction(upload));

  if (failure) {
    fireAnalyticsEvent(
      ANALYTICS.CATEGORIES.UPLOADER,
      ANALYTICS.EVENTS.UPLOAD_VIDEO_ERROR,
      {
        reason: failure,
      },
    );
  }
}

export const UploadsProvider = ({ children }: IProps) => {
  const location = useLocation();
  const { briefShortHash } = useParams<IDetailParams>();

  const disabled = !Session.isAuthenticated() || !briefShortHash;

  const { data, loading, error } = useQuery<IGetBriefVideosWithVariants>(
    GET_BRIEF_VIDEOS_WITH_VARIANTS,
    {
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      skip: disabled,
      variables: { shortHash: briefShortHash },
    },
  );

  const [expanded, setExpanded] = useState(true);

  const [state, dispatch] = useReducer(uploadsReducer, {
    adUnit: "all",
    language: "",
    sequence: false,
    submissionComplete: false,
    uploads: [],
  });

  const brief = data?.brief as IBrief;

  const addUploads = useCallback(
    (files: File[], startTime: string, initialFailure?: string) => {
      if (!brief) {
        return;
      }

      setExpanded(true);

      const { approvedFiles: validFiles, rejectedFiles: invalidFilesTuple } =
        sequenceValidation(files);

      // ValidFiles (valid sequences and things that weren't sequenced) go through preflight
      validFiles.forEach((file: File) => {
        let failure = "";
        const [preflightError, reason, parsedFilename] = uploadsPreflight(
          file,
          brief,
        );

        if (initialFailure || preflightError) {
          failure = initialFailure || reason;
        }

        addUpload(file, startTime, dispatch, parsedFilename, failure);
      });

      invalidFilesTuple.forEach((tuple: RejectedFileTuple) => {
        const [file, failure] = tuple;
        addUpload(file, startTime, dispatch, undefined, failure);
      });
    },
    [brief, dispatch, setExpanded],
  );

  useEffect(() => {
    setExpanded(false);
  }, [location.pathname, setExpanded]);

  if (disabled) {
    return <>{children}</>;
  }

  if (error) {
    return <DataError error={error} />;
  }

  if (loading || !data || !brief) {
    return null;
  }

  return (
    <>
      {!!state.uploads.length && (
        <UploadsWrapper expanded={expanded}>
          <UploadsManager
            addUploads={addUploads}
            brief={brief}
            dispatch={dispatch}
            expanded={expanded}
            onExpand={setExpanded}
            uploads={state.uploads}
          />
        </UploadsWrapper>
      )}
      <GlobalVideoDropzone
        addUploads={addUploads}
        id={IDS.UPLOADS.GLOBAL_DROPZONE}
      >
        {children}
      </GlobalVideoDropzone>
    </>
  );
};

export default UploadsProvider;
